import React from 'react'
import Sidebar from '../component/dashboard/Sidebar';
import { ToastContainer, toast } from 'react-toastify';
import { Route, Routes } from 'react-router-dom';
import Web3waitlistapplicants from '../component/dashboard/Web3waitlistapplicants';
import Waitlistdetails from '../component/dashboard/Waitlistdetails';

const Web3Waitlist = () => {
    return(
        <div>
            <ToastContainer closeButton={false} />
            {/* <OutsideClickHandler
                onOutsideClick={() => {
                    closeNavMobile();
                }}
            > */}
                <div className="layout_dashboard-sidebar" id="sidebar">
                    <Sidebar />
                </div>
            {/* </OutsideClickHandler> */}
            <div className="layout_dashboard-content">
                <div className="dashboard-nav">
                    {/* <img src={menu} alt="menu" className="dashboard-nav-menu" onClick={showNavMobile} /> */}
                    <div className="dashboard-nav-user">
                        <div>
                            <h2>AD</h2>
                        </div>
                        <p>Admin</p>
                    </div>
                </div>

                <Routes>
                    <Route path="/" exact element={<Web3waitlistapplicants  />} />
                    <Route path="waitlist-details" element={<Waitlistdetails />} />
                </Routes>
            </div>
        </div>
    );
}

export default Web3Waitlist