import APIKit from './apikit';
import {APIKit2} from './apikit';


let token = '';
let tokenObject = '';
try {
    let details = sessionStorage.getItem('admin_details');
    tokenObject = JSON.parse(details)
    token = tokenObject.token;
} catch (err) {
    //err
}

export const getAllUsers = (page) => {
    // return APIKit.get(`/fetchAllUser?page=${page}`)
    return APIKit.get(`api/v1/admin/fetchAllUser?page=${page}`)
}
export const getAllWeb3Users = (page) => {
    // return APIKit.get(`/fetchAllUser?page=${page}`)
    return APIKit.get(`api/v1/admin/web3applicants?page=${page}`)
}
export const getAllWeb3Waitlist = (page) => {
    // return APIKit.get(`/fetchAllUser?page=${page}`)
    return APIKit.get(`api/v1/admin/web3waitlist?page=${page}`)
}

export const getAllBatchBUsers = (page) => {
    // return APIKit.get(`/fetchAllUserB?page=${page}`)
    return APIKit.get(`api/v1/admin/fetchAllUserB?page=${page}`)
}

export const getSingleUser = (id) => {
    // return APIKit.get(`/fetchData/${id}`)
    return APIKit.get(`api/v1/admin/fetchData/${id}`)
}
export const getSingleWeb3User = (id) => {
    // return APIKit.get(`/fetchData/${id}`)
    return APIKit.get(`/api/v1/admin/web3applicant/${id}`)
}

export const getGuarantorData = (id) => {
    // return APIKit.get(`/fetchGuarantorData?user_id=${id}`)
    return APIKit.get(`api/v1/admin/fetchGuarantorData?userId=${id}`)
}

export const changeUserStatus = (status, payload) => {
    // return APIKit.patch(`/onChangeStatus?status=${status}`, payload)
    console.log(tokenObject)
    console.log(token)
    return APIKit.patch(`api/v1/admin/onChangeStatus?status=${status}`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    // return APIKit.patch(`api/v1/admin/status=${status}`, payload)
}


export const sendSelectedMails = (type, payload) => {
    // return APIKit.patch(`/${type}All`, payload)
    // return APIKit.patch(`${type}All`, payload)
    // console.log(tokenObject)
    // console.log(token)
    return APIKit.patch(`api/v1/admin/${type}All`, payload, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
 
}

export const fetchUserByStatus = (status) => {
    // return APIKit.get(`/filter?status=${status}`)
    return APIKit.get(`api/v1/admin/filter?status=${status}`)
}

export const searchUser = (email) => {
    // return APIKit.get(`/search?email=${email}`)
    return APIKit.get(`api/v1/admin/searchA?email=${email}`)
}

export const approveGuarantors = (userId) => {
    // console.log(userId)
    return APIKit.post(`api/v1/admin/approveguarantor/${userId}`)
}
export const rejectGuarantors = (userId) => {
    // console.log(userId)
    return APIKit.post(`api/v1/admin/rejectguarantor/${userId}`)
}
export const rejectGuarantorOne = (userId) => {
    // console.log(userId)
    return APIKit.post(`api/v1/admin/rejectOne/${userId}`)
}
export const rejectGuarantorTwo = (userId) => {
    // console.log(userId)
    return APIKit.post(`api/v1/admin/rejectOne1/${userId}`)
}
export const changePaymentStatus = (userId) => {
    // console.log(userId)
    return APIKit.post(`api/v1/admin/paymentStatus/${userId}`)
}
export const getTalentCityCommercial = () => {
    return APIKit2.get(`api/v1/admin/getCommercial`)
}
export const getTalentCityResidents = () => {
    return APIKit2.get(`api/v1/admin/getResidents`)
}
export const getSingleCommercialUser = (id) => {
    // return APIKit.get(`/fetchData/${id}`)
    return APIKit2.get(`api/v1/admin/getCommercial/${id}`)
}
export const getSingleResidentialUser = (id) => {
    // return APIKit.get(`/fetchData/${id}`)
    return APIKit2.get(`api/v1/admin/getResidents/${id}`)
}
